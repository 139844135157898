import React, { FC } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { GAME_STATES } from "../ducks/game/types";

import boardActions from "../ducks/board/actions";
import gameActions from "../ducks/game/actions";
import { PlayerState, Player } from "../ducks/player/types";
import playerActions from "../ducks/player/actions";
import Grid from "./Grid";
import DraggablePiece, { Coord, ICoord, Piece, NullPiece } from "./Piece";
// TODO: change Piece to IPiece?

interface BoardProps {
  boardState: Piece[][];
  gameState: any; // FIXME
  playerStates: PlayerState;
  selectPlayerPiece: any; // FIXME
  movePieceOnBoard: any;
  movePieceFromSetupToBoard: any;
  removePieceFromBoard: any;
  finishPlayer1Setup: any;
  finishPlayer2Setup: any;
  changeTurnToNextPlayer: any;
  swapPieces: any;
  recordGameAction: any;
  finishGame: any;
}

const Board: FC<BoardProps> = ({
  boardState,
  gameState,
  playerStates,
  selectPlayerPiece,
  movePieceOnBoard,
  movePieceFromSetupToBoard,
  removePieceFromBoard,
  finishPlayer1Setup,
  finishPlayer2Setup,
  changeTurnToNextPlayer,
  swapPieces,
  recordGameAction,
  finishGame
}) => {
  const player1 = playerStates.player1;
  const player2 = playerStates.player2;

  function _isPlayer1SettingUp(): boolean {
    return gameState.state === GAME_STATES.SETUP_PLAYER_1;
  }

  function _isPlayer2SettingUp(): boolean {
    return gameState.state === GAME_STATES.SETUP_PLAYER_2;
  }

  function _isGridEmpty({ x, y }: ICoord): boolean {
    return !boardState[y][x].id;
  }

  function _isSelectedPieceOutOfPlay(player: any): boolean {
    return _.some(player.outOfPlay, piece => {
      return player.selectedPiece.id === piece.id;
    });
  }

  // TODO: what a mess!
  /* setup logic
    if we click on an empty grid and nothing selected, no op

    [x] if we click on an empty grid with a setup piece selected
      - auto select the next setup piece, if we can
      - create a duplicate of that piece onto the board
      - remove the setup piece from setup
          
    [x] if we click on an empty grid with a placed piece selected
      - create a duplicate of that piece onto that coord
      - remove the existing piece on the coord

    if we click on a placed piece with a placed piece selected
      - if it's the same piece, unselect it
      - if its not the same piece, swap places, and unselect both
        */

  function _handleClickGridDuringSetup({
    player,
    coord
  }: {
    player: Player;
    coord: Coord;
  }): void {
    const x = coord.x;
    const y = coord.y;
    if (
      _isGridEmpty({ x, y }) &&
      player.selectedPiece.id &&
      player.selectedPiece.isValidSetupCoord({ x, y })
    ) {
      if (!_isSelectedPieceOutOfPlay(player)) {
        // if its already on the board
        removePieceFromBoard({ piece: player.selectedPiece });
      }
      movePieceFromSetupToBoard({ piece: player.selectedPiece, y, x });
    } else if (!_isGridEmpty({ x, y })) {
      // if there are no more setup pieces and something is selected
      // swap the two and deselect
      if (player.outOfPlay.length === 0 && player.selectedPiece.id) {
        swapPieces(boardState[y][x], player.selectedPiece);
        selectPlayerPiece({ playerId: player.id });
      } else {
        // if nothing else is selected, select it
        selectPlayerPiece({ piece: boardState[y][x], playerId: player.id });
      }
    }
  }

  function _handleClickGridDuringTurn({
    player,
    coord
  }: {
    player: Player;
    coord: Coord;
  }): void {
    const x = coord.x;
    const y = coord.y;
    const adjacentMovableCoords = player.selectedPiece.coord.getAdjacentMovableCoords();
    if (boardState[y][x].playerId === player.id && boardState[y][x].canMove) {
      selectPlayerPiece({ piece: boardState[y][x], playerId: player.id });
    } else if (
      player.selectedPiece.id &&
      _.some(adjacentMovableCoords, c => c.equals(coord)) &&
      player.selectedPiece.canMove &&
      boardState[y][x].playerId !== player.id
    ) {
      recordGameAction(
        `Player ${player.selectedPiece.playerId} move - ${player.selectedPiece.display} to coord ${coord.y}, ${coord.x}`
      );
      if (_isGridEmpty({ y, x })) {
        movePieceOnBoard({ piece: player.selectedPiece, coord });
        changeTurnToNextPlayer(player);
      } else {
        if (boardState[y][x].isFlag()) {
          finishGame(player);
          recordGameAction(`Player ${player.id} wins!`);
        } else {
          const battleResults = player.selectedPiece.battle(boardState[y][x]);
          const resultWinnerStrings = battleResults.winner.isNullPiece()
            ? "0 winners."
            : `Player ${battleResults.winner.playerId}'s ${battleResults.winner.display} wins.`;
          let resultLoserStrings = "";

          battleResults.losers.forEach(loser => {
            removePieceFromBoard({ piece: loser });
            resultLoserStrings = resultLoserStrings.concat(
              `Player ${loser.playerId}'s ${loser.display} loses. `
            );
          });
          movePieceOnBoard({ piece: battleResults.winner, coord });

          recordGameAction(
            `Battle results: ${resultWinnerStrings} ${resultLoserStrings}`
          );
          changeTurnToNextPlayer(player);
        }
      }
    }
  }

  // TODO: randomize
  function autoFill(pieces: Piece[]): void {
    let x = 0;
    let y = 0;
    pieces.forEach((piece: Piece) => {
      let placed = false;
      while (!placed) {
        if (_isGridEmpty({ y, x }) && piece.isValidSetupCoord({ x, y })) {
          movePieceFromSetupToBoard({ piece, y, x });
          placed = true;
        } else {
          if (x === 9) {
            x = 0;
            y++;
          } else {
            x++;
          }
        }
      }
    });
  }

  function clickGrid({ x, y }: { x: number; y: number }): void {
    const coord = new Coord({ x, y });
    switch (gameState.state) {
      case GAME_STATES.SETUP_PLAYER_1:
        _handleClickGridDuringSetup({ player: player1, coord });
        break;

      case GAME_STATES.SETUP_PLAYER_2:
        _handleClickGridDuringSetup({ player: player2, coord });
        break;

      case GAME_STATES.TURN_PLAYER_1:
        _handleClickGridDuringTurn({ player: player1, coord });
        break;

      case GAME_STATES.TURN_PLAYER_2:
        _handleClickGridDuringTurn({ player: player2, coord });
        break;
    }
  }

  return (
    <div className="game">
      <div className="setup">
        {player1.outOfPlay.map((piece: Piece) => {
          return (
            <DraggablePiece
              piece={piece}
              key={piece.id}
              onClick={() => {
                if (_isPlayer1SettingUp()) {
                  piece.id === player1.selectedPiece.id
                    ? selectPlayerPiece({ playerId: player1.id })
                    : selectPlayerPiece({ playerId: player1.id, piece });
                }
              }}
              selected={piece.id === player1.selectedPiece.id}
            />
          );
        })}
        {_isPlayer1SettingUp() ? (
          player1.outOfPlay.length === 0 ? (
            <button onClick={finishPlayer1Setup}>Confirm setup</button>
          ) : (
            <div>
              <button onClick={() => autoFill(player1.outOfPlay)}>
                Autofill/Randomize
              </button>
            </div>
          )
        ) : null}
      </div>
      <div className="game-board">
        {_.times(10, x => {
          return (
            <div className="row" key={x}>
              {_.times(10, y => {
                const piece = boardState[y][x];
                return (
                  <Grid
                    key={y}
                    x={x}
                    y={y}
                    piece={piece}
                    selected={
                      piece
                        ? player1.selectedPiece.id === piece.id ||
                          player2.selectedPiece.id === piece.id
                        : false
                    }
                    onClick={() => clickGrid({ x, y })}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="setup">
        {player2.outOfPlay.map((piece: Piece) => {
          return (
            <DraggablePiece
              piece={piece}
              key={piece.id}
              onClick={() => {
                if (_isPlayer2SettingUp()) {
                  piece.id === player2.selectedPiece.id
                    ? selectPlayerPiece({ playerId: player2.id })
                    : selectPlayerPiece({ playerId: player2.id, piece });
                }
              }}
              selected={piece.id === player2.selectedPiece.id}
            />
          );
        })}
        {_isPlayer2SettingUp() ? (
          player2.outOfPlay.length === 0 ? (
            <button onClick={finishPlayer2Setup}>Confirm setup</button>
          ) : (
            <button onClick={() => autoFill(player2.outOfPlay)}>
              Autofill/Randomize
            </button>
          )
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  boardState: Piece[][];
  gameState: any; // FIXME
  playerStates: PlayerState;
}) => {
  return {
    boardState: state.boardState,
    gameState: state.gameState,
    playerStates: state.playerStates
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => void) => {
  return {
    swapPieces: (p1: Piece, p2: Piece) => {
      const newP1Coord: Coord = p2.coord;
      const newP2Coord: Coord = p1.coord;
      dispatch(
        boardActions.movePiece({
          piece: p1,
          coord: newP1Coord
        })
      );
      dispatch(
        boardActions.movePiece({
          piece: p2,
          coord: newP2Coord
        })
      );
    },
    finishPlayer1Setup: () => {
      dispatch(playerActions.resetSelectedPiece("1"));
      dispatch(gameActions.setGameState(GAME_STATES.SETUP_PLAYER_2));
    },
    finishPlayer2Setup: () => {
      dispatch(playerActions.resetSelectedPiece("2"));
      dispatch(gameActions.setGameState(GAME_STATES.TURN_PLAYER_1));
    },
    changeTurnToNextPlayer: (player: Player) => {
      if (player.id === "1") {
        dispatch(playerActions.resetSelectedPiece("1"));
        dispatch(gameActions.setGameState(GAME_STATES.TURN_PLAYER_2));
      } else {
        dispatch(playerActions.resetSelectedPiece("2"));
        dispatch(gameActions.setGameState(GAME_STATES.TURN_PLAYER_1));
      }
    },
    finishGame: (winningPlayer: Player) => {
      switch (winningPlayer.id) {
        case "1":
          dispatch(gameActions.setGameState(GAME_STATES.WINNER_PLAYER_1));
          break;
        case "2":
          dispatch(gameActions.setGameState(GAME_STATES.WINNER_PLAYER_2));
          break;
        default:
          break;
      }
    },
    movePieceOnBoard: ({ piece, coord }: { piece: Piece; coord: Coord }) => {
      dispatch(boardActions.removePiece(piece));
      dispatch(boardActions.movePiece({ piece, coord }));
    },
    movePieceFromSetupToBoard: ({
      piece,
      y,
      x
    }: {
      piece: Piece;
      y: number;
      x: number;
    }) => {
      const coord = new Coord({ x, y });
      dispatch(boardActions.movePiece({ piece, coord }));
      dispatch(
        playerActions.putPieceInPlay({
          pieceId: piece.id,
          playerId: piece.playerId
        })
      );
    },
    removePieceFromBoard: ({ piece }: { piece: Piece }) => {
      dispatch(boardActions.removePiece(piece));
      dispatch(playerActions.removePieceFromPlay(piece));
    },
    selectPlayerPiece: ({
      piece = new NullPiece(),
      playerId
    }: {
      piece: Piece;
      playerId: string;
    }) => {
      dispatch(playerActions.selectPiece({ piece, playerId }));
    },
    recordGameAction: (action: string) => {
      dispatch(gameActions.recordGameAction(action));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Board);
