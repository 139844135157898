import types from "./types";

export function setGameState(gameState: string) {
  return { type: types.SET_GAME_STATE, gameState };
}

export function recordGameAction(gameAction: string) {
  return { type: types.RECORD_GAME_ACTION, gameAction };
}

export function resetGameHistory() {
  return { type: types.RESET_GAME_HISTORY };
}

export default { setGameState, recordGameAction, resetGameHistory };
