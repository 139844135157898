import React from "react";
import { Link } from "react-router-dom";

import dirt from "./dirt.png"; // FIXME
import walk from "./walk.gif"; // FIXME
import "./Home.css";

function Home() {
  return (
    <div className="home">
      <div>
        <img src={dirt} alt="dirt" />
      </div>
      <p>
        <Link to="/creature" className="link">
          c
        </Link>{" "}
        o n s t r u c t i o n
      </p>
      <div>
        <img src={walk} alt="walk" />
      </div>
    </div>
  );
}

export default Home;
