import types from "./types";
import { Piece, NullPiece } from "../../components/Piece";

// TODO: refactor this?
export function putPieceInPlay({
  pieceId,
  playerId
}: {
  pieceId: string;
  playerId: string;
}) {
  return { type: types.PUT_PIECE_IN_PLAY, pieceId, playerId };
}

// Given a piece, add the piece back into the player's outOfPlay
export function removePieceFromPlay(piece: Piece) {
  return { type: types.REMOVE_PIECE_FROM_PLAY, piece };
}

export function resetSelectedPiece(playerId: string) {
  return { type: types.SELECT_PIECE, piece: new NullPiece(), playerId };
}

export function selectPiece({
  piece,
  playerId
}: {
  piece: Piece;
  playerId: string;
}) {
  return { type: types.SELECT_PIECE, piece, playerId };
}

export function resetPiecesForNewGame(playerId: string) {
  return { type: types.RESET_PIECES_FOR_NEW_GAME, playerId };
}

export default {
  putPieceInPlay,
  removePieceFromPlay,
  resetSelectedPiece,
  resetPiecesForNewGame,
  selectPiece
};
