import React from "react";

import DraggablePiece, { Piece } from "./Piece";

interface GridProps {
  y: number;
  x: number;
  piece: Piece;
  onClick?: () => void; // TODO?
  selected: boolean;
}

function Grid(props: GridProps) {
  const terrainGrids = [
    { y: 4, x: 2 },
    { y: 5, x: 2 },
    { y: 4, x: 3 },
    { y: 5, x: 3 },
    { y: 4, x: 6 },
    { y: 5, x: 6 },
    { y: 4, x: 7 },
    { y: 5, x: 7 }
  ];
  // TODO: use lodash some here
  const terrain =
    terrainGrids.filter(grid => grid.y === props.y && grid.x === props.x)
      .length > 0
      ? true
      : false;

  return (
    <div
      className={"grid" + (terrain ? " immovable" : "")}
      onClick={props.onClick}
    >
      {/* y:{props.y} x:{props.x} */}
      {props.piece.id ? (
        <DraggablePiece piece={props.piece} selected={props.selected} />
      ) : null}
    </div>
  );
}

export default Grid;
