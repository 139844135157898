import { Piece } from "../../components/Piece";

const playerActions = {
  SELECT_PIECE: "PLAYER.SELECT_PIECE",
  PUT_PIECE_IN_PLAY: "PLAYER.PUT_PIECE_IN_PLAY",
  REMOVE_PIECE_FROM_PLAY: "PLAYER.REMOVE_PIECE_FROM_PLAY",
  RESET_PIECES_FOR_NEW_GAME: "RESET_PIECES_FOR_NEW_GAME"
};

export interface Player {
  id: string;
  selectedPiece: Piece;
  outOfPlay: Piece[];
}

export interface PlayerState {
  [key: string]: Player;
  player1: Player;
  player2: Player;
}

export default playerActions;
