import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import strategoApp from "./components/Stratego/reducers";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// TODO: can i do this only within the stratego route?
const store = createStore(
  strategoApp,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// const wits = ["platypus platitudes", "caffeine cacophony"];
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
console.log("platypus platitudes");
const platypus = [
  "                                ,===.",
  '                ,=="""""==.__.="    o"._____',
  '          ,=.=="                      _____/',
  '  ,====.,"    ,            , \\,==="""',
  ' <       ,==)  \\"\'"==.__.==)  \\',
  "  `----''    `\"             `\""
];
const quote = "        quack";
platypus.forEach((plat, i) => {
  console.log(plat, i === 1 ? quote : "");
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
