import _ from "lodash";
import { Piece } from "../../components/Piece";

// remove a piece from a piece ID, returning the array
function removePiece({
  pieceArr,
  pieceId
}: {
  pieceArr: Piece[];
  pieceId: string;
}): Piece[] {
  return _.filter(pieceArr, piece => {
    return piece.id !== pieceId;
  });
}

export default removePiece;
