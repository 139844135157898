import types from "./types";
import { Coord, Piece } from "components/Stratego/components/Piece";

export function movePiece({ piece, coord }: { piece: Piece; coord: Coord }) {
  return { type: types.MOVE_PIECE, piece, coord };
}

export function removePiece(piece: Piece) {
  return { type: types.REMOVE_PIECE, piece };
}
export function resetBoard() {
  return { type: types.RESET_BOARD };
}
export default { movePiece, removePiece, resetBoard };
