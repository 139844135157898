import _ from "lodash";
import { Piece, NullCoord } from "../../components/Piece";

function setupPieces(playerId: string): Piece[] {
  return [
    new Piece({
      playerId,
      display: "1",
      value: 1,
      id: `${playerId}-1-0`,
      coord: new NullCoord()
    }),
    new Piece({
      playerId,
      display: "2",
      value: 2,
      id: `${playerId}-2-0`,
      coord: new NullCoord()
    }),
    ..._.times(
      2,
      i =>
        new Piece({
          playerId,
          display: "3",
          value: 3,
          id: `${playerId}-3-${i}`,
          coord: new NullCoord()
        })
    ),
    ..._.times(
      3,
      i =>
        new Piece({
          playerId,
          display: "4",
          value: 4,
          id: `${playerId}-4-${i}`,
          coord: new NullCoord()
        })
    ),
    ..._.times(
      4,
      i =>
        new Piece({
          playerId,
          display: "5",
          value: 5,
          id: `${playerId}-5-${i}`,
          coord: new NullCoord()
        })
    ),
    ..._.times(
      4,
      i =>
        new Piece({
          playerId,
          display: "6",
          value: 6,
          id: `${playerId}-6-${i}`,
          coord: new NullCoord()
        })
    ),
    ..._.times(
      4,
      i =>
        new Piece({
          playerId,
          display: "7",
          value: 7,
          id: `${playerId}-7-${i}`,
          coord: new NullCoord()
        })
    ),
    ..._.times(
      5,
      i =>
        new Piece({
          playerId,
          display: "8",
          value: 8,
          id: `${playerId}-8-${i}`,
          coord: new NullCoord()
        })
    ),
    ..._.times(
      8,
      i =>
        new Piece({
          playerId,
          display: "9",
          value: 9,
          id: `${playerId}-9-${i}`,
          coord: new NullCoord()
        })
    ),
    ..._.times(
      6,
      i =>
        new Piece({
          playerId,
          display: "B",
          value: 10,
          id: `${playerId}-10-${i}`,
          coord: new NullCoord(),
          canMove: false
        })
    ),
    new Piece({
      playerId,
      display: "S",
      value: 11,
      id: `${playerId}-11-0`,
      coord: new NullCoord()
    }),
    new Piece({
      playerId,
      display: "F",
      value: 12,
      id: `${playerId}-12-0`,
      coord: new NullCoord(),
      canMove: false
    })
  ];
}

export default setupPieces;
