import React from "react";

import creature from "./creature.gif"; // FIXME
import "./Creature.css";

function Creature() {
  return (
    <div className="creature">
      <div>
        <img src={creature} alt="creature" />
      </div>
    </div>
  );
}

export default Creature;
