const gameActions = {
  SET_GAME_STATE: "SET_GAME_STATE",
  RECORD_GAME_ACTION: "RECORD_GAME_ACTION",
  RESET_GAME_HISTORY: "RESET_GAME_HISTORY"
};

export const GAME_STATES = {
  SETUP: "SETUP",
  SETUP_PLAYER_1: "SETUP_PLAYER_1",
  SETUP_PLAYER_2: "SETUP_PLAYER_2",
  TURN_PLAYER_1: "TURN_PLAYER_1",
  TURN_PLAYER_2: "TURN_PLAYER_2",
  WINNER_PLAYER_1: "WINNER_PLAYER_1",
  WINNER_PLAYER_2: "WINNER_PLAYER_2"
};

export default gameActions;
