import React, { FC } from "react";
import { connect } from "react-redux";

import Board from "./components/Board";

import { GAME_STATES } from "./ducks/game/types";
import { PlayerState } from "./ducks/player/types";

import boardActions from "./ducks/board/actions";
import gameActions from "./ducks/game/actions";
import playerActions from "./ducks/player/actions";

import "./Stratego.css";

interface StrategoProps {
  gameState: any; // FIXME?
  playerStates: PlayerState;
  resetGame: any;
}

const Stratego: FC<StrategoProps> = ({
  gameState,
  playerStates,
  resetGame
}) => {
  return (
    <div className="stratego">
      <Board />
      <div className="game-history">
        {(gameState.state === GAME_STATES.WINNER_PLAYER_1 ||
          gameState.state === GAME_STATES.WINNER_PLAYER_2) && (
          <div>
            <button onClick={() => resetGame()}>Reset Game</button>
          </div>
        )}
        Game History
        {gameState.history.map((action: string, index: number) => {
          return <div key={index}>-{action}</div>;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  gameState: any; // FIXME?
  playerStates: PlayerState;
}) => {
  return {
    gameState: state.gameState,
    playerStates: state.playerStates
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => void) => {
  return {
    resetGame: () => {
      dispatch(gameActions.setGameState(GAME_STATES.SETUP_PLAYER_1));

      dispatch(playerActions.resetPiecesForNewGame("1"));
      dispatch(playerActions.resetPiecesForNewGame("2"));

      dispatch(boardActions.resetBoard());

      dispatch(gameActions.resetGameHistory());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stratego);
