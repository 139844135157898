import types, { GAME_STATES } from "./types";

function gameStateReducer(
  state: {
    state: string;
    history: string[];
  } = {
    state: GAME_STATES.SETUP_PLAYER_1,
    history: []
  },
  action: { type: string; gameState: string; gameAction: string }
) {
  let updatedState = { ...state };
  switch (action.type) {
    case types.SET_GAME_STATE:
      updatedState.state = action.gameState;
      break;
    case types.RECORD_GAME_ACTION:
      updatedState.history.push(action.gameAction);
      break;
    case types.RESET_GAME_HISTORY:
      updatedState.history = [];
      break;
    default:
      break;
  }
  return updatedState;
}

export default gameStateReducer;
