import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";
import Creature from "./components/Creature/Creature";
import Home from "./components/Home/Home";
import Stratego from "./components/Stratego/Stratego";

function App() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route path="/creature" component={Creature} />
      <Route path="/stratego" component={Stratego} />
    </Router>
  );
}

export default App;
