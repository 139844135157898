import { combineReducers } from "redux";

import boardDuck from "./ducks/board";
import gameDuck from "./ducks/game";
import playerDuck from "./ducks/player";

const strategoApp = combineReducers({
  gameState: gameDuck.reducers,
  boardState: boardDuck.reducers,
  playerStates: playerDuck.reducers
});

export default strategoApp;
