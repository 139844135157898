import _ from "lodash";

import { Coord, Piece, NullPiece } from "../../components/Piece";

import types from "./types";

const initialBoardState: Piece[][] = _.times(10, () =>
  _.times(10, () => new NullPiece())
);

function boardStateReducer(
  state: Piece[][] = initialBoardState,
  action: {
    type: string;
    piece: Piece;
    pieceId: string;
    coord: Coord;
  }
) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case types.MOVE_PIECE:
      let piece = action.piece;
      piece.coord = action.coord;
      newState[action.coord.y][action.coord.x] = piece;
      return newState;
    case types.REMOVE_PIECE:
      if (!action.piece.coord.isCoordOutOfBounds()) {
        newState[action.piece.coord.y][action.piece.coord.x] = new NullPiece();
      }
      return newState;
    case types.RESET_BOARD:
      const resetBoardState: Piece[][] = _.times(10, () =>
        _.times(10, () => new NullPiece())
      );

      newState = resetBoardState;
      return newState;
    default:
      return state;
  }
}

export default boardStateReducer;
