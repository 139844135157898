import React from "react";
import _ from "lodash";
// import Draggable from "react-draggable";

export interface IPiece {
  playerId: string;
  display: string;
  value: number;
  id: string;
  coord: Coord;
  canMove?: boolean;
}

export interface ICoord {
  x: number;
  y: number;
}

interface Battle {
  winner: Piece;
  losers: Piece[];
}

export class Coord {
  x: number;
  y: number;

  constructor({ x, y }: ICoord) {
    this.x = x;
    this.y = y;
  }

  equals(coord: Coord): boolean {
    return this.x === coord.x && this.y === coord.y;
  }

  isCoordOutOfBounds(): boolean {
    return this.x === -1 && this.y === -1;
  }

  isImmovable(): boolean {
    const immovableCoords = [
      new Coord({ y: 4, x: 2 }),
      new Coord({ y: 5, x: 2 }),
      new Coord({ y: 4, x: 3 }),
      new Coord({ y: 5, x: 3 }),
      new Coord({ y: 4, x: 6 }),
      new Coord({ y: 5, x: 6 }),
      new Coord({ y: 4, x: 7 }),
      new Coord({ y: 5, x: 7 })
    ];
    return _.some(immovableCoords, coord => {
      return coord.equals(this);
    });
  }

  // return potentially four coords - up down left right
  getAdjacentMovableCoords(): Coord[] {
    const nullCoord = new NullCoord();
    const left =
      this.x > 0 ? new Coord({ x: this.x - 1, y: this.y }) : nullCoord;
    const right =
      this.x < 9 ? new Coord({ x: this.x + 1, y: this.y }) : nullCoord;
    const up = this.y > 0 ? new Coord({ x: this.x, y: this.y - 1 }) : nullCoord;
    const down =
      this.y < 9 ? new Coord({ x: this.x, y: this.y + 1 }) : nullCoord;
    return [left, right, up, down].filter(
      coord => !coord.equals(nullCoord) && !coord.isImmovable()
    );
  }
}

export class NullCoord extends Coord {
  constructor() {
    super({ x: -1, y: -1 });
  }
}

export class Piece {
  playerId: string;
  display: string;
  value: number;
  id: string;
  coord: Coord;
  canMove?: boolean;

  constructor({
    playerId,
    display,
    value,
    id,
    coord = new NullCoord(),
    canMove = true
  }: IPiece) {
    this.playerId = playerId;
    this.display = display;
    this.value = value;
    this.id = id;
    this.coord = coord;
    this.canMove = canMove;
  }

  isNullPiece(): boolean {
    return this.value === 0;
  }

  isBombDefuser(): boolean {
    return this.value === 8;
  }

  isBomb(): boolean {
    return this.value === 10;
  }

  isSpy(): boolean {
    return this.value === 11;
  }

  isFlag(): boolean {
    return this.value === 12;
  }

  isValidSetupCoord({ x, y }: ICoord): boolean {
    switch (this.playerId) {
      case "1":
        return y < 4;
      case "2":
        return y > 5;
    }
    return false;
  }

  battle(defendingPiece: Piece): Battle {
    // TODO: simplify this
    let battleResults: Battle = {
      winner: new NullPiece(),
      losers: []
    };
    if (defendingPiece.isBomb()) {
      if (this.isBombDefuser()) {
        battleResults.winner = this;
        battleResults.losers = [defendingPiece];
      } else {
        battleResults.winner = defendingPiece;
        battleResults.losers = [this];
      }
    } else if (this.isSpy()) {
      if (defendingPiece.isSpy()) {
        battleResults.winner = new NullPiece();
        battleResults.losers = [this, defendingPiece];
      } else {
        battleResults.winner = this;
        battleResults.losers = [defendingPiece];
      }
    } else {
      if (this.value < defendingPiece.value) {
        battleResults.winner = this;
        battleResults.losers = [defendingPiece];
      } else if (this.value === defendingPiece.value) {
        battleResults.winner = new NullPiece();
        battleResults.losers = [this, defendingPiece];
      } else {
        battleResults.winner = defendingPiece;
        battleResults.losers = [this];
      }
    }
    return battleResults;
  }
}

export class NullPiece extends Piece {
  constructor() {
    const props = {
      playerId: "0",
      display: "",
      value: 0,
      id: "",
      coord: new NullCoord(),
      canMove: false
    };
    super(props);
  }
}

interface DraggablePieceProps {
  onClick?: () => void;
  piece: Piece;
  selected?: boolean; //TODO?
}

function DraggablePiece(props: DraggablePieceProps) {
  return (
    <div
      className={
        `piece player${props.piece.playerId}` +
        (props.selected ? " selected" : "")
      }
      onClick={props.onClick}
    >
      {props.piece.display}
    </div>
  );
}

export default DraggablePiece;
