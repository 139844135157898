import types, { PlayerState } from "./types";
import { Piece, NullPiece } from "../../components/Piece";

import utils from "../utils";

function playerReducer(
  state: PlayerState = {
    player1: {
      id: "1", // TODO ???
      selectedPiece: new NullPiece(),
      outOfPlay: utils.setupPieces("1")
    },
    player2: {
      id: "2", // ???
      selectedPiece: new NullPiece(),
      outOfPlay: utils.setupPieces("2")
    }
  },
  action: { type: string; playerId: string; pieceId: string; piece: Piece }
) {
  let newState = { ...state };
  const player = "player" + action.playerId;
  let updatedOutOfPlay;
  switch (action.type) {
    case types.SELECT_PIECE:
      newState[player].selectedPiece = action.piece;
      return newState;

    case types.PUT_PIECE_IN_PLAY:
      updatedOutOfPlay = utils.removePiece({
        pieceArr: newState[player].outOfPlay,
        pieceId: action.pieceId
      });
      const updatedSelectedPiece =
        updatedOutOfPlay.length > 0 ? updatedOutOfPlay[0] : new NullPiece();

      newState[player].selectedPiece = updatedSelectedPiece;
      newState[player].outOfPlay = updatedOutOfPlay;
      return newState;

    case types.REMOVE_PIECE_FROM_PLAY:
      let playerInState = `player${action.piece.playerId}`; // TODO: fix this with const player, above
      updatedOutOfPlay = newState[playerInState].outOfPlay;
      updatedOutOfPlay.push(action.piece);
      newState[playerInState].outOfPlay = updatedOutOfPlay;
      return newState;

    case types.RESET_PIECES_FOR_NEW_GAME:
      newState[player].selectedPiece = new NullPiece();
      newState[player].outOfPlay = utils.setupPieces(action.playerId);
      return newState;

    default:
      return state;
  }
}

export default playerReducer;
